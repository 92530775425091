import React from 'react';

import { RouteComponentProps, Router } from '@reach/router';
import Layout from 'src/components/layout/layout';
import InviteAdmin from 'src/components/leagues-invite-admin/invite-admin';
import { PrivateRoute } from 'src/components/private-route/private-route';
import SEO from 'src/components/seo';

const InviteAdminPage = () => {
  return (
    <Router basepath="/organisations/manage-schools/invite-admin">
      <InviteAdminRoute path="/:schoolId" />
    </Router>
  );
};

interface InviteAdminRouteProps extends RouteComponentProps {
  schoolId?: string;
}

export const InviteAdminRoute: React.FC<InviteAdminRouteProps> = ({ schoolId }) => {
  return (
    <Layout>
      <SEO title="Invite School Admin" />
      <PrivateRoute>
        <InviteAdmin schoolId={schoolId} />
      </PrivateRoute>
    </Layout>
  );
};

export default InviteAdminPage;
