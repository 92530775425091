import React, { useMemo } from 'react';

import { useMutation } from '@apollo/client';
import { navigate } from 'gatsby';
import { useTranslation } from 'react-i18next';
import { meshGatewayClient } from 'src/apollo/client';
import { useOrgId, useOrgSlug } from 'src/apollo/local-state';
import InvitePerson from 'src/components/invite-person/invite-person';
import { INVITE_USER_TO_CLAIM_ORGANISATION } from 'src/components/organisations/organisations-queries';
import { getEnvConfig } from 'src/config/config';
import {
  InviteUserToClaimOrganisation,
  InviteUserToClaimOrganisationVariables,
} from 'src/graphql-types/InviteUserToClaimOrganisation';
import { NavigationState } from 'src/utils/typedefs/navigation';

interface InviteAdminProps {
  schoolId: string | undefined;
}

const InviteAdmin: React.FC<InviteAdminProps> = ({ schoolId }) => {
  const { t } = useTranslation();
  const orgId = useOrgId();
  const organisationSlug = useOrgSlug(orgId);
  const baseLeaguesRegistrationUrl = getEnvConfig().BASE_LEAGUE_REGISTRATION_URL;
  const [inviteDirector, { loading, error }] = useMutation<
    InviteUserToClaimOrganisation,
    InviteUserToClaimOrganisationVariables
  >(INVITE_USER_TO_CLAIM_ORGANISATION, { client: meshGatewayClient });

  const errorMessage = useMemo(() => {
    if (error?.graphQLErrors && error.graphQLErrors.length === 1) {
      return t('error sending admin invite');
    }
    return error?.message;
  }, [error, t]);
  const onSubmit = (values) => {
    inviteDirector({
      variables: {
        emailOrExternalId: values.externalId,
        organisationId: schoolId as string,
        targetUrl: `${baseLeaguesRegistrationUrl}/state/${organisationSlug}/school/${schoolId}/claim`,
      },
    }).then(() => {
      const state: NavigationState = {
        popup: {
          message: t('invite admin success'),
        },
      };
      navigate('/organisations/manage-schools', { state });
    });
  };

  return <InvitePerson variant="id-or-email" errorMessage={errorMessage} loading={loading} handleSubmit={onSubmit} />;
};

export default InviteAdmin;
